<template>
  <div>
    <profile-photos v-if="actor" :photos="actor.photos" class="mb-4">
    </profile-photos>
    <FileUpload
      mode="advanced"
      name="images"
      accept="image/*"
      :multiple="true"
      @uploader="uploadImages"
      :customUpload="true"
      invalidFileSizeMessage="{0}: Nieprawidłowy rozmiar pliku, Rozmiar pliku powinien wynosić mniej niż {1}."
      chooseLabel="Dodaj zdjęcia"
    >
      <template #empty>
        <ProgressSpinner v-if="isLoading" class="spinner" strokeWidth="4" />
        <p>Przeciągnij i upuść zdjęcia tutaj, aby je przesłać</p>
      </template>
    </FileUpload>
  </div>
</template>
<script>
import ProfilePhotos from "../../components/ProfilePhotos.vue";
import ActorService from "../../services/ActorService.js";
import { toastMixin } from "../../mixins/toastMixin";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Photos",
  components: { ProfilePhotos },
  mixins: [toastMixin],
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters("actors", ["actor"]),
  },
  methods: {
    ...mapActions("actors", { storeLoadActor: "actors/loadActor" }),
    async uploadImages(ev) {
      try {
        this.isLoading = true;
        await ActorService.uploadImages(this.actor._id, ev.files);
        await this.storeLoadActor(this.actor._id);
        this.displayToast("success", "Zdjęcia zostały dodane.");
      } catch (err) {
        this.displayToast("error", "Przesyłanie zdjęć nie powiodło się.");
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables";
@import "../../styles/main";
@import "../../styles/main_page";
::v-deep(.p-button.p-component.p-fileupload-choose) {
  @extend .action-btn;
}
::v-deep(.p-button.p-component.p-button-icon-only) {
  border-radius: 50%;
  background: white;
  border: none;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14),
    0px 1px 5px rgba(0, 0, 0, 0.12);
}
::v-deep(.p-fileupload.p-fileupload-advanced.p-component
    > .p-fileupload-buttonbar) {
  border-top-left-radius: 1.875rem;
  border-top-right-radius: 1.875rem;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14),
    0px 1px 5px rgba(0, 0, 0, 0.12);
}
::v-deep(.p-fileupload.p-fileupload-advanced.p-component
    > .p-fileupload-content) {
  border-bottom-left-radius: 1.875rem;
  border-bottom-right-radius: 1.875rem;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14),
    0px 1px 5px rgba(0, 0, 0, 0.12);
}
.spinner {
  position: absolute;
  top: -3.2rem;
  left: 16rem;
  height: 37px;
}
</style>
